<template>

  <div>
    <v-sheet v-if="!blockReady">
      <v-progress-linear class="wsRounded" indeterminate :color="wsLIGHTCARD" height="24" />
    </v-sheet>


    <v-sheet
        class="overflow-hidden"
        style="position:relative;"
        ref="container"
        :height="height"
        :style="!blockReady ? 'opacity:0' : ''"

    >
      <v-sheet width="800" min-width="800" >
        <email-block-view
            :uuid="uuid"
            preview
            style="scale : 0.327;transform-origin: top left "
            disable-margin
            ref="block"
            @ready="handleBlockReady"
        />
      </v-sheet>

    </v-sheet>


  </div>




</template>
<script>
import finemailer from "@/modules/finemailer/mixins/finemailer";
import emailBlockView from "@modules/finemailer/components/emailConstructor/viewer/emailBlockView.vue";

export default {
  name: "emailBlockPreview",
  mixins : [finemailer],
  components : {
    emailBlockView
  },
  props : {
    uuid : {
      type : String,
    },
  },
  data() {
    return {
      blockReady : false,
      height : 100,
    }
  },
  methods : {
    handleBlockReady() {
      setTimeout(()=> {
        const block = this.$refs.block.$el
        const box = block.getBoundingClientRect()
        this.height = box.height
        this.blockReady = true
      } , 200)

    }
  }
}
</script>



<style scoped>

</style>